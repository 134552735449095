body {
  margin: 0;
  padding: 0 !important;
}

button {
	outline: none !important;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

header {
	padding: 0 !important;
}



.autocomplete-container {
  position: relative;
}

.autocomplete-container [data-placement="bottom-start"] {
  width: 400px;
  max-width: calc(100vw - 6px);
}

.autocomplete-container [role="combobox"] {
  max-width: 100%;
}
