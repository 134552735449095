.main-body-error {
	background-image: url('../Resources/images/tools.svg');
	background-position: center;
	background-size: 30vw 30vw;
	background-repeat: no-repeat;
	top: 0;
	width: 100%;
	height: 80vh;
}

.main-body-error p {
	position: absolute;
	margin-top: 68vh;
	text-align: center;
	width: 100%;
	font-family: 'Open Sans', sans-serif;
	font-size: 4vw;
}
